import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import ContentfulRichText from '../../components/ContentfulRichText'
import { ContentfulPage } from '../../../types/graphql-types'
import SEO from '../../components/SEO'
import LayoutBlank from '../../components/Layout/Blank'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const Content = styled.section`
  ${tw`container pt-8`}

  h2 {
    ${tw`leading-none mb-3 pb-2 md:mb-5`}
  }

  p {
    ${tw`mb-8`}
  }

  .bg-gray-300 {
    p {
      ${tw`last:mb-0`}
    }
  }

  ul {
    ${tw`list-disc ml-5 mb-8`}
  }

  ol {
    ${tw`list-decimal ml-5 mb-8`}
  }

  ul,
  ol {
    li {
      p:first-of-type {
        ${tw`mb-0`}
      }
    }
  }
`

export const query = graphql`
  query CustomerAgreementAppPage {
    contentfulPage(slug: { eq: "customer-agreement/" }, type: { eq: "legal" }) {
      title
      seoTitle
      seoDescription
      type
      content {
        raw
        references {
          ... on ContentfulCustomBlock {
            __typename
            id
            contentful_id
            classes
            content {
              raw
            }
          }
        }
      }
    }
  }
`

const CustomerAgreementApp: React.FC<Props> = ({ data }: Props) => {
  return (
    <LayoutBlank>
      <SEO
        title={data.contentfulPage.seoTitle as string}
        description={data.contentfulPage.seoDescription as string}
        noIndex={true}
      />
      <Content>
        <h1 className="mb-8">{data.contentfulPage.title}</h1>
        <ContentfulRichText document={data.contentfulPage.content} />
      </Content>
    </LayoutBlank>
  )
}

export default CustomerAgreementApp
